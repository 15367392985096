import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20')
];

export const server_loads = [0];

export const dictionary = {
		"/": [~4],
		"/account": [~5,[2]],
		"/account/address-book": [~6,[2]],
		"/account/password": [~7,[2]],
		"/auth": [~8,[3]],
		"/auth/contact-sales": [~9,[3]],
		"/auth/forgot-password": [~10,[3]],
		"/auth/invite": [~11,[3]],
		"/auth/reset-password": [~12,[3]],
		"/cart": [13],
		"/checkout": [~14],
		"/orders": [~15],
		"/products": [~16],
		"/products/[slug]": [~17],
		"/sentry-example": [18],
		"/settings": [19],
		"/thank-you": [20]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';